button:hover {
    cursor: pointer 
}
.btn:focus{
    box-shadow: none;
}
.lsb_listItem > .active, .lsb_listItem > .active .nav-text {
    background: #FF766B;
    text-decoration: none;
    color: #fff;
}
.lsb_listItem > .active .img_sideicon{
    background-color: #F96559;
}
.lsb_listItem > .active .img_sideicon > img {
    filter: brightness(0) invert(1);
}
.activeLink, .activeLink * {
    color: #fa5261 !important;
}
.activeLink .sid_adrmnr {
    filter: invert(57%) sepia(49%) saturate(3780%) hue-rotate(325deg) brightness(100%) contrast(96%) !important
}
.notification_dropdown{
    min-width: 300px;
    border: none;
    left: auto !important;
    right: 0%;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
}
.notification_dropdown .active {
    color: #fff;
    background-color: #F96559;
    border-color: #F96559;
}
.theme_primary_text{
    color: #fa5261;
}
.theme_primary_text:hover {
    color: #fa5261;
    text-decoration: none;
}
.qr_img{
    width: 400px;
}

.scanQRContainer {
    width: 400px;
    height: 400px;
    margin: 20px auto;
    background: transparent;
    display: block;
    outline: 2px solid #29455b;
}

.save_btn button.disable-btn, button.disable-btn, .submt_btn button.disable-btn{
    cursor: not-allowed;
    background: #d8d7d7;
    color: #333;
    border: none;
    box-shadow: none;
}
.save_btn button.disable-btn:hover, button.disable-btn:hover, .submt_btn button.disable-btn:hover{
    color: #333 !important;
    background: #d8d7d7 !important;
    border: none !important;
}

.table td {
    vertical-align: middle;
    border-top: 0;
}
.theme-btn {
    color: #F96559;
}
.theme-btn:hover {
    color: #F96559;
}

.header_profile_dropdown.show {
    position: absolute !important;
    left: auto !important;
    right: -100px !important;
    transform: translate3d(-100px, 53px, 0px) !important
}

@media (max-width: 1200px){
    .left_sidebarWrap{
        width: 200px !important;
        box-shadow: none !important;
        border-top-right-radius: 0;
        border-right: none !important;
    }
    .notification_dropdown{
        transform: translate3d(0px, 45px, 0px) !important;
    }
}
@media (max-width: 991px){
    .left_sidebarWrap {
        left: -200px !important;
    }
    .left_sidebarWrap.closed{
        left: 0 !important;
    }
}
@media (max-width: 580px){
    .notification_dropdown {
        transform: translate3d(-50px, 45px, 0px) !important;
        left: 100% !important;
    }    
}
@media(max-width: 580px) {
    .header_profile_dropdown.show {
        left: 100% !important;
        right: 0px !important;
        transform: translate3d(-40px, 53px, 0px) !important;
    }
}
@media(max-width: 510px) {
    .scanQRContainer{
        width: 100%;
        height: 100%;
        
    }
    .scanQRContainer > section > div {
        border: 5px solid #F96559 !important;        
    }
    .qr_wrapper{
        padding: 1px 10px;
    }
}

