/* Preset CSS Styling Start's Here */ 
/*
 v1.0
Author: Irfan Ali - http://engr.irfanali970@gmail.com */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    background:transparent;
}

 

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    text-decoration: none;
}

input, select {
    vertical-align:middle;
    outline: none;
}
input:focus, select:focus, button:focus {
    outline: none;
}



/* Language drop down and Radial css */

.vodiapicker{
    display: none; 
  }
  
  #languag_a{
    padding-left: 0px;
  }
  
  #languag_a img, .btn-select img {
      width: 28px;
  }
  
  #languag_a li{
    list-style: none;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  
  #languag_a li:hover{
   background-color: #F4F3F3;
  }
  
  #languag_a li img{
    margin: 5px;
  }
  
  #languag_a li span, .btn-select li span {
      margin-left: 11px;
      color: #29455B;
      font-family: Poppins-Medium;
      font-size: 16px;
  }
  
  /* item list */
  
  .lang_select_b {
      display: none;
      width: 100%;
      max-width: 366px;
      width: 253px;
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 5px;
      position: absolute;
      right: 0;
      top: 61px;
      background: #fff;
      z-index: 1;
  }
  .open{
    display: show !important;
  }
  
  .btn-select {
      margin-top: 10px;
      width: 100%;
      max-width: 351px;
      border-radius: 5px;
      background-color: #FDDFDD;
      padding: 10px 40px 10px 18px;
      border-radius: 29px;
      border: none;
      color: #29455B;
      position: relative !important;
      cursor: pointer;
      border: 1px solid #FDDFDD ;
  }
  .btn-select:after {
      content: "\f107";
      font: normal normal normal 14px/1 FontAwesome;
      font-weight: 900;
      font-size: 20px;
      color: #f965596e;
      position: absolute;
      right: 15px;
      top: 52%;
      transform: translateY(-59%);
  }
  .btn-select li {
      list-style: none;
      float: left;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
  }
  
  .btn-select:hover li{
    margin-left: 0px;
  }
  
  .btn-select:hover{
    background-color: #F4F3F3;
    border: 1px solid transparent;
    box-shadow: inset 0 0px 0px 1px #ccc;
    
    
  }
  
  .btn-select:focus{
     outline:none;
  }
  
  .lang-select {
      margin-left: 11px;
      position: relative;
  }


   /* tabs index page  */
   /* Style the tab */
.food_tab {
    overflow: hidden;  
  }
  
  /* Style the buttons inside the tab */
  .food_tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .food_tab button:hover
  {
    border: 1px solid #F96559;
  }
  .food_tab button:hover .tb_sp
  {
    display: flex;
  }
  
  /* Create an active/current tablink class */
   
  
  /* Style the tab content */
  .food_tabcontent {
      /* display: none; */
      padding: 6px 0px;
      /* border: 1px solid #ccc; */
      border-top: none;
  }
   /* tabs index page  ends*/
  .tb_blkk
  {
    display: block;
  }
 

/* Preset CSS Styling End's Here  */
/* form design starts here */

.formWrapper .cardHeader h1 {
    font-size: 16px;
    padding-bottom: 5px;
}

.inlineField label {
    margin: 0;
}

.formWrapper label {
    /* color: #3b3f5c; */
    font-size: 14px;
    font-weight: 500;
}

.formWrapper input:not([type='checkbox'], [type='radio']), .formWrapper select, .formWrapper textarea {
    border-radius: 7px;
    padding: 10px;
    font-size: 14px;
    height: 45px;
    border: 1px solid #e0e6ed;
    -webkit-box-shadow: 0 0 0 0 rgb(31 45 61 / 10%);
    box-shadow: 0 0 0 0 rgb(31 45 61 / 10%);
    transition: 0.4s ease all;
    width: 100%;
}

.formWrapper textarea {
    height: auto;
}

.formWrapper input:not([type='checkbox'], [type='radio']):focus, .formWrapper select:focus, .formWrapper textarea:focus {
    -webkit-box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
    box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
    border: 1px solid #304aca;
}

.searchWrapper select {
    width: 30%;
    float: left;
    border-right: none;
    border-radius: 7px 0 0 7px;
}

.searchWrapper input:not([type='checkbox'], [type='radio']) {
    width: 70%;
    float: left;
    border-radius: 0 7px 7px 0;
}

.customCheck {
    position: relative;
    margin: 0 4px;
}

.customCheck::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background: #dcdcdc;
    border-radius: 50%;
}

.customCheck::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: #dcdcdc;
    border: 1px solid #dcdcdc;
    /*  transition: 0.4s ease all; */
    cursor: pointer;
}

.customCheck:checked:after {
    border-color: #ffffff;
    border-width: 2px;
    border-top: none;
    border-left: none;
    width: 5px;
    transform: translate(-50%, -60%) rotate(45deg);
    background: #00C99C;
}

.customCheck:checked:before {
    background: #00C99C;
}

.inlineInput {
    display: flex;
    align-items: center;
}

.inlineInput input {
    width: 100%;
    margin-left: 10px;
}

.radio_btn label {
    padding-right: 30px;
    padding-left: 10px;
}

.radio_btn {
    padding: 0 30px;
}

.custom_checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.custom_checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*  FONTS REGEXTERED HERE  */

@font-face {
    font-family: 'OpenSans-Italic';
    src: url("../fonts/OpenSans-Italic.ttf");
}
@font-face {
    font-family: 'OpenSans-MediumItalic';
    src: url("../fonts/OpenSans-MediumItalic.ttf");
}
@font-face {
    font-family: 'Poppins-Black';
    src: url("../fonts/Poppins-Black.ttf");
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url("../fonts/Poppins-ExtraBold.ttf");
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url("../fonts/Poppins-ExtraLight.ttf");
}

@font-face {
    font-family: 'Poppins-Light';
    src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url("../fonts/Poppins-Medium.ttf");
}
@font-face {
    font-family: 'Poppins-Regular';
    src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url("../fonts/Poppins-SemiBold.ttf");
}
@font-face {
    font-family: 'Poppins-Thin';
    src: url("../fonts/Poppins-Thin.ttf");
}
@font-face {
    font-family: 'mont-bold';
    src: url("../fonts/Montserrat-Bold.ttf");
}
/* Custom Styling Starts Here */
html{
    scroll-behavior: 'smooth';
}
body{
    font-family: 'Poppins-Regular';
    background: #f2f7fb;
    /* overflow: hidden; */
}
.qr_modal > div.modal, .pric_modal > div.modal {
    overflow: auto !important;
}
.heading>h3,.heading>h4{
    font-family: 'Poppins-Bold';
}
.heading>h3{
    font-size: 18px;
}
.heading > h4 {
    font-size: 16px;
    color: #29455B;
    margin: 0 0 20px;
    font-family: 'Poppins-SemiBold';
}
.rm_order a {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #F96559;
}
.primry_btn{
    display: inline-block;
    margin: 6px;
    font-size: 14px;
    border-radius: 12px;
    padding: 8px;
    min-width: 120px;
    cursor: pointer;
    background: #F9764D;
    border:#F9764D;
    color: #fff;
}
.primry_btn.crdBtn{
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0px;
    border-radius: 16px 0 16px 0;
    min-width: auto;
    padding: 8px 22px;
    font-size: 12px;
}
/* Custom Styling Ends Here */
/* Scroll BaR Styling Start Here */
/* width */
::-webkit-scrollbar{
    width: 4px;
    height: 4px; 
  }
  
  /* Track */
  ::-webkit-scrollbar-track{
    background: #969696; 
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    margin: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb{
    background: #f95a62; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover{
    background: #f95a62; 
  }
/* Scroll BaR Styling Ends Here */
/* Navbar Styling Starts Here */
.nav_innerWrap {
    padding: 20px;
    display: flex;
    align-items: center;
    background:#ffffff;
    margin-bottom: 20px;
}
.nav_logo{
    flex: 0 0 300px;
    text-align: center;
    transition: ease-in-out 0.2s;
}
.logo_img {
    width: 144px;
    height: 55px;
    margin: auto;
}
.logo_img>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    
}
.nav_innerWrap.closed .nav_logo {
    flex: 0 0 200px;
}
.nav-control {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}
.toggleMenu {
    cursor: pointer;
    display: inline-block;
    left: 0px;
    position: relative;
    width: 26px;
    height: auto;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}
.toggleMenu span {
    background: #2E4765;
    display: block;
    height: 2px;
    border-radius: 3px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: auto;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}
.toggleMenu span:nth-child(1) {
    width: 20px;
}
.toggleMenu span:nth-child(2) {
    width: 26px;
}
.toggleMenu span:nth-child(3) {
    width: 22px;
}
.toggleMenu.active span:nth-child(1), .toggleMenu.active span:nth-child(3) {
    width: 10px;
    height: 2px;
}
.toggleMenu.active span:nth-child(1) {
    -webkit-transform: translateY(2px) rotate( 45deg);
    transform: translateY(2px) rotate( 45deg);
}
.toggleMenu.active span:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 22px;
    height: 2px;
}
.toggleMenu.active span:nth-child(3) {
    -webkit-transform: translateY(-2px) rotate( -45deg);
    transform: translateY(-2px) rotate( -45deg);
}
.nav_dropdown{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    border: none; 
}
.nav_dropdown>img {
    width: 100%;
    height: 84%;
    object-fit: contain;
}
button.notification {
    background: #F1592A;
    width: 45px;
    height: 45px;
    border-radius: 12px;
}
button.qr_cino {
    background: #F7941F;
    width: 45px;
    height: 45px;
    border-radius: 12px;
    cursor: pointer;
}
button.cart_cino{
    background: #F1592A;
    margin-left: 16px;
    display: none;
}
.left_nav,.right_nav{
display: flex;
align-items: center;
}
.left_nav{
    width: 100%;
}
.right_nav {
    padding: 0 25px;
}
.right_nav .dropdown+.dropdown{
padding-left: 16px;
}
.usr_titl h3 ,.mc_amount p {
    width: max-content;
}
.mc_amount{
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 12px;
}
.mc_amount>p>strong {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Poppins-SemiBold';
}
.serch_bar {
    width: 100%;
    position: relative;
}
.usr_titl h3 {
    font-family: 'Poppins-SemiBold';
    font-size: 26px;
}
.serch_bar>input{
    width: 100%;
    max-width: calc(100% - 150px);
    margin-left: auto;
    display: block;
    padding: 14px 14px 14px 50px;
    border-radius: 50px;
    border: none;
    background: #f2f7fb;
    transition: ease-in-out 0.2s;
}
.serch_bar>input::placeholder{
    font-size: 14px;
}
/* Navbar Styling Ends Here */
/* Main content styling strts here */
/* left sidebar styling starts here */
.left_sidebarWrap {
    width: 300px;
    background: #ffff;
    position: fixed;
    top: 115px;
    left: 0;
    padding: 8px 24px;
    height: calc(100vh - 120px);
    overflow: auto;
    transition: ease-in-out 0.2s;

}
.left_sidebarWrap.closed {
    width: 200px;
}
.left_sidebarWrap.closed .nav-text{
display: none;
}
.lsb_innerWrap .lsb_list {
    display: flex;
    list-style: none;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 70px);
    margin: auto;
    align-items: center;
    transition: ease-in-out 0.2s;
}
.left_sidebarWrap.closed .lsb_list{
    max-width: calc(100% - 0px);
}
.profile_img>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.lsb_innerWrap .lsb_list .lsb_listItem{
    width: 100%;
    padding: 10px 0;
    transition: ease-in-out 0.2s;
}
.left_sidebarWrap.closed .lsb_list .lsb_listItem{
    width: auto;
}

.lsb_listItem h3 {
    font-size: 22px;
    text-align: center;
    font-family: 'Poppins-Bold';
    transition: ease-in-out 0.2s;
}
.left_sidebarWrap.closed .lsb_listItem h3{
    font-size: 18px;
}
.lsb_innerWrap .lsb_list .lsb_listItem a{
    font-size: 18px;
    color: #969ba0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    padding: 0;
    margin: 0 auto;
    border-radius: 16px;
    display: block;
}
.lsb_innerWrap .lsb_list .lsb_listItem a:hover{
    background: #FF766B;
    text-decoration: none;
}
 
.side_loigow {
    display: flex;
    align-items: center;
    margin-right: 19px;
    transition: ease-in-out 0.2s;
}
.left_sidebarWrap.closed .lsb_list .lsb_listItem .side_loigow{
    margin-right: 0;
}
.img_sideicon {
    width: 64px;
    height: 59px;
    display: inline-block;
    background: #F0F6FA;
    padding: 16px;
    border-radius: 16px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lsb_innerWrap .lsb_list .lsb_listItem a:hover .img_sideicon{
    background: #F96559;
}
.lsb_innerWrap .lsb_list .lsb_listItem a:hover .img_sideicon img{
    filter: brightness(0) invert(1);
}
.img_sideicon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.nav-text {
    margin: 0 28px 0 16px;
}
.lsb_innerWrap .lsb_list .lsb_listItem a:hover .nav-text{
    color: #fff;
}
/* left sidebar styling ends here */
/* content Area styling starts here */
.contentArea{
    max-width: calc(100% - 720px);
    width: 100%;
    margin-left: 300px;
    transition: ease-in-out 0.2s;
    /* background: #fff; */
}
.contentArea.closed{
    max-width: calc(100% - 620px);
    margin-left: 200px;
    /* background: #fff; */
}
.categoryBar{
    background-color: #fff;
    margin-right: 20px;
    padding: 20px 0px 0 20px;
    border-radius: 0 16px 16px 0;
}
.categoryList{
    display: flex;
    list-style: none;
    /* flex-wrap: wrap; */
    overflow: auto;
}
.categoryList button {
    background-color: #f2f7fb;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px 15px 5px 5px;
    transition: 0.3s;
    font-size: 14px;
    border: 1px solid #f9655900;
    border-radius: 50px;
    min-width: 150px;
    font-weight: 700;
    margin: 20px 25px 20px 0px;
}
.categoryList button.active {
    border: 1px solid #F96559;
}
.food_tab button:hover .categoryList button.active {
    border: 1px solid #F96559 !important;
}
.tbWrap {
    display: flex;
    align-items: center;
    position: relative;
}
.categoryList button.active .tb_img {
    border: 1px solid #f9764d;
}
.tb_img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 24px;
    object-fit: cover;
}
.tb_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
button:hover + .tb_img > img {
    border: 1px solid #f9764d !important;
}
.categoryList button.active .tb_sp {
    display: flex;
}
.tb_sp {
    background: transparent linear-gradient(180deg, #F97E46 0%, #F94C6D 100%) 0% 0% no-repeat padding-box;
    width: 38px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    border-radius: 24px;
    display: none;
}
.tb_sp span {
    font-size: 10px;
    color: #FEFEFE;
    font-family: 'Poppins-Medium';
}
.productArea {
    height: calc(100vh - 289px);
    overflow-y: auto;
    overflow-x: hidden;
    background: #F2F7FB;
}
.productWrapp {
    padding: 20px 0 0 20px;
    background: #eef4f9;
}
.productList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}
.productItem {
    flex: 0 0 calc((100% / 3) - 20px);
    min-width: 280px; 
    width: 100%;
    margin-right: 20px;
    margin-bottom: 40px;
}
.productItem a
{
    text-decoration: none;
}
.productHeader {
    border-radius: 16px 16px 0 0;
    overflow: hidden;
}
.productImg {
    height: 180px;
}
.productImg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.productBody {
    border-radius: 0 0 16px 16px;
    background: #fff;
    padding: 10px 14px;
    font-size: 13px;
    position: relative;
}
.productTitle {
    font-size: 16px;
    margin: 4px 0 9px;
    font-family: 'Poppins-Bold';
    color: #565656;
}
.productDesc {
    font-family: 'OpenSans-MediumItalic';
    font-size: 14px;
    color: #777777;
    line-height: 1.3;
    margin-bottom: 16px;
}
.infoList {
    list-style: none;
    display: flex;
    color: #D8D8D8;
    font-size: 12px;
}
.infoList li +li{
    margin-left: 25px;
    position: relative;
}
.infoList li +li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -14px;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background: #d8d8d8;
    border-radius: 8px;
}
/* content Area styling ends here */
.top_catbar {
    min-width: fit-content;
}
.pc_hdng h3 {
    color: #F96559;
    font-family: Poppins-Semibold;
    margin-bottom: 4px;
} 

.pc_hdng > h6 {
    color: #F96559;
    font-family: Poppins-Semibold;
    font-size: 14px;
}

/* Cart Bar styling starts here */
.cart_head{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.cartBar {
    width: 400px;
    background: #ffff;
    position: fixed;
    top: 116px;
    right: 0;
    padding: 8px 24px;
    height: calc(100vh - 120px);
    overflow: auto;
    transition: ease-in-out 0.2s;
    border-radius: 16px;
    margin-right: 20px;
}
.cart_body {
    height: calc(100vh - 399px);
    overflow: auto;
}
ul.cartList {
    list-style: none;
    padding-right: 6px;
}
li.cartItem {
    display: flex;
    background: #f2f7fb;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
    font-family: 'Poppins-Bold';
}
.cartItem + .cartItem{
margin-top: 20px;
}
.cp_title>h3 {
    font-size: 14px;
}
.cp_img {
    height: 80px;
    width: 70px;
    flex :0 0 70px;
    margin-right: 15px;
}
.cp_price {
    font-family: 'Poppins-Medium';
    margin-top: 6px;
}
.cp_info {
    width: 100%;
}
.cp_total{
    flex : 0 0 70px;
    margin-right: 8px;
}
.cp_img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cp_totalPrice {
    font-size: 16px;
    font-family: 'Poppins-Bold';
    color: #393939;
}
.incrn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 6px;
}
.incrn .button {
    padding: 9px;
    cursor: pointer;
    background: #F96559;
    color: white;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    display: inline-block;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.incrn input {
    width: 24px;
    background: #f2f7fb;
    margin: 0 5px;
    height: 20px;
    text-align: center;
    border: none;
    font-family: 'Poppins-Medium';
    color: #393939;
    font-size: 16px;
}
.sub_totolWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 9px 0 0 0;
    align-items: center;
    border-top: 2px dashed;
    padding: 18px 0;
    border-bottom: 1px solid #DCDCDC;
}
.totl_h h3 {
    font-size: 14px;
    font-family: 'Poppins-SemiBold';
    color: #F96559;
}
.totl_price h4 {
    font-size: 16px;
    font-family: 'Poppins-Bold';
    color: #F96559;
}
.sub_totlButn {
    width: 100%;
    margin: 32px 0;
}
.btn_totl
{
    transition: 0.4s ease;
}
.sub_totlButn button {
    width: 100%;
    padding: 12px 0;
    color: #fff;
    border: 1px solid #f97050;
    background: linear-gradient(180deg, #F97E46, #F94C6D);
    border-radius: 12px;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    cursor: pointer;
    transition: 0.4s ease;

}

.sub_totlButn button:hover {
    color: #f97050;
    background: #fff;
    transition: 0.4s ease;
}

/* Cart Bar styling ends here */
/* Main content styling strts here */

/* ************************************************************************************************************************ */
/* *****************************************New Admin Panel******************************************************************************* */
       
.header_secc {
    background: #FEFEFE;
    box-shadow: 9px 17px 26px #a1add119;
    opacity: 1;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.rest_logo {
    width: 205px;
    height: 51px;
    margin-left: 39px;
    flex: 0 0 10%;
    transition: 0.4s ease;
}
.rest_logo img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
 
.menu_togg {
    cursor: pointer;
    display: inline-block;
    left: 0px;
    position: relative;
    width: 26px;
    height: auto;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}
.menu_togg span {
    background: #2E4765;
    display: block;
    height: 4px;
    border-radius: 3px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: auto;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
}
.menu_togg span:nth-child(1) {
    width: 20px;
    margin-right: auto;
    margin-left: 0;
}
.menu_togg span:nth-child(2) {
    width: 26px;
}
.menu_togg span:nth-child(3) {
    width: 26px;
}
.menu_togg.active span:nth-child(1), .menu_togg.active span:nth-child(3) {
    width: 10px;
    height: 2px;
}
.menu_togg.active span:nth-child(1) {
    -webkit-transform: translateY(2px) rotate( 45deg);
    transform: translate(15px, 3px) rotate( 45deg);
}
.menu_togg.active span:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 22px;
    height: 2px;
}
.menu_togg.active span:nth-child(3) {
    -webkit-transform: translateY(-2px) rotate( -45deg);
    transform: translate(2px, -3PX) rotate( -45deg);
}
.srch_icon {
    position: absolute;
    display: inline-block;
    left: 11%;
    top: 30%;
}
.srch_icon i {
    font-size: 18px;
    color: #87878782;
}


.hdr_rit {
    margin-right: 25px;
    flex: 0 0 18%;
}

.prof_wraper {
    display: flex;
    align-items: center;
    justify-content: end;
}
.log_img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #f96c5461;
    border: 4px solid #F96758CF;
    opacity: 1;
}
.log_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hdr_mid {
    width: 100%;
    display: flex;
    margin: 0 92px 0 10px;
    align-items: center;
    flex: 0 0 55%;
    transition: 0.4s ease;
}
.new_srchbr>input
{
    margin-right: auto !important;
}
.qr_nw
{
    margin-right: 15px;
}
.side_mnuwrap.actv .sid_descrp p {
    color: #f96559 !important; 
}
.side_mnuwrap:hover .sid_descrp p {
    color: #f96559 !important; 
}
.side_mnuwrap:hover .sid_adrmnr { 
    filter: invert(57%) sepia(49%) saturate(3780%) hue-rotate(325deg) brightness(100%) contrast(96%) !important;
}

.side_mnuwrap.actv .sid_adrmnr { 
    filter: invert(57%) sepia(49%) saturate(3780%) hue-rotate(325deg) brightness(100%) contrast(96%) !important;
}
 
.rest_logo.closed {
    width: 28px !important;
    height: 28px !important;
    margin-left: 12px !important;
    flex: 0 0 7% !important;
    transition: 0.4s ease;
}
.hdr_mid.closed {
    flex: 0 0 67% !important;
}
.d-flex.mob_notific {
    display: none !important;
}

.afmn_r_wrapper {
    display: flex;
}
.adminr_left {
    flex: 0 0 15%;
    background: #FEFEFE;
    height: 100vh;
    box-shadow: 9px 17px 26px #a1add119;
    opacity: 1;
    transition: all .35s ease-in-out;
    z-index: 1;
}
.adminr_right {
    flex: 0 0 85%;
    background: #F8F5F5 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100vh;
    transition: 0.4s ease;
}
.side_mnuwrap a {
    text-decoration: none;
}
.admin_sidewrapper {
    display: inline-flex;
    align-items: center;
    margin: 20px 0;
    text-decoration: none; 
}
.sid_adrmnr {
    width: 26px;
    height: 26px;
    margin-right: 17px;
}
.sid_adrmnr img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sid_descrp p {
    color: #29455B;
    font-size: 14px;
    font-family: 'Poppins-Semibold';
}
.sid_descrp.closed {
    display: none !important;
}
.adminr_left.closed {
    flex: 0 0 5% !important; 
}
.adminr_right.closed {
    flex: 1 0 93% !important;
}
.side_mnuwrap {
    padding-left: 49px;
    padding-right: 12px;
    cursor: pointer;
}
.admnr_sidbr {
    height: calc(100vh - 256px);
    overflow-y: auto;
    margin-top: 53px;
}
.copy_ritt p {
    font-size: 14px;
    text-align: center;
    color: #A7A7A7;
    font-family: 'Poppins-Regular';
    margin-top: 34px;
}
.right_dashboard {
    margin: 10px 35px;
    /* height: calc(100vh - 109px); */
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #F8F5F5;
    padding-bottom: 50px;       
}   

.dash_ritWraper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
}
.dash_rit_hdng h3 {
    font-size: 26px;
    font-family: 'Poppins-SemiBold';
    color: #29455B;
    margin: 25px 0;
}
.dash_rit_hdng h3 {
    font-size: 26px;
    font-family: 'Poppins-SemiBold';
    color: #29455B;
    margin: 25px 0;
}
.dfh_2_hdng h2 {
    font-size: 16px;
    color: #A7A7A7;
    margin: 25px 0;
} 
.progress {
    width: 108px;
    height: 108px !important;
    float: left;
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative;
}
.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #e2d8f8;
    position: absolute;
    top: 0;
    left: 0;
}
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress .progress-left {
    left: 0;
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress .progress-right {
    right: 0;
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-2 1.8s linear forwards;
  }
  .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%; 
    font-size: 24px;
    color: #fff;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  .progress.blue .progress-bar {
    border-color: #F96559;
}
  .progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress.yellow .progress-bar {
    border-color: #fdba04;
  }
  .progress.yellow .progress-right .progress-bar {
    animation: loading-2 1.8s linear forwards;
  }
  .progress.yellow .progress-left .progress-bar {
    animation: none;
  }
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(130deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  .admntxtwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
    padding: 10px 37px;
    background: #FEFEFE;
    position: relative;
    margin-bottom: 35px;
    min-height: 132px;
}
.crd_add {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 25px;
    height: 25px;
}
.crd_add img {
     width: 100%;
     height: 100%;
     object-fit: contain;
}
.admn_txt p {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    color: #A7A7A7;
    margin-bottom: 3px;
    margin-right: 12px;
}
.admn_txt h3 {
    font-size: 26px;
    font-family: 'Poppins-Bold';
    color: #29455B;
}
.admn_crcl {
    position: relative;
}

.dsh_main_grph {
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
    background: #fefefe;
    padding: 14px 21px;
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
}
#chart {
    max-width: 100%;
}
.chrt_dsc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dsc_h1 h3, .dsc_h2 h3 {
    font-size: 16px;
    font-family: 'Poppins-Bold';
    color: #29455B;
}
.dsc_h1 p, .dsc_h2 p {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #A7A7A7;
    margin-top: 3px;
}
.dsc_h2 p span
{
    color: #88EAB5;
    font-size: 12px;
    font-family: 'Poppins-Regular';
}
.dn_menu_left {
    background: #FEFEFE;
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
    padding: 12px 12px;
}

.dfh_2_hdng h4 {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #A7A7A7;
} 
.dmnu_h h3 {
    font-size: 16px;
    color: #29455B;
    font-family: 'Poppins-Bold';
    margin-bottom: 15px;
    text-align: center;
    padding-top: 29px;
} 
.dmnu_h p {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #A7A7A7;
    text-align: center;
}
.dmnu_1 h4 {
    font-size: 13px;
    font-family: 'Poppins-SemiBold';
    color: #7b8791;
    white-space: nowrap;
} 
.dmnu_2 h4 {
    font-size: 14px;
    color: #3B3B3B;
    padding: 0 10px;
    font-family: 'Poppins-SemiBold';
}
.dd_mnu_Wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    padding: 0 18px;
    margin-bottom: 28px;
}
.dmnu_2wrppe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
}
.dnmh4 h4 {
    font-size: 14px;
    font-family: 'Poppins-Bold';
    color: #29455B;
}
.dnmp4 p {
    font-size: 12px;
    font-family: 'Poppins-Medium';
    color: #BCBCBC;
}
.ad_newmenu {
    width: 64px;
    height: 48px;
}
.ad_newmenu img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.dsh_menu_linechrt {
    background: #FEFEFE;
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
    height: 100%;
}
.dinner_menuu {
    margin-bottom: 200px;
}
/* Style the tab */
.chart_tab {
    overflow: hidden;
    border: 1px solid #fddfdd;
    background-color: #fddfdd;
    border-radius: 26px;
    margin-top: 19px;
}
/* Style the buttons inside the tab */
.chart_tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 6px 16px;
    margin: 11px 14px;  
    transition: 0.3s;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #29455B;
}

/* Change background color of buttons on hover */
.chart_tab button:hover { 
   
    background: #FA8980 !important;
    border-radius: 24px;
    color: #fff !important;
}

/* Create an active/current tablink class */
.chart_tab button.active { 
    padding: 6px 16px;
    margin: 11px 14px;  
    background: #FA8980;
    border-radius: 24px;
    color: #fff;
}

/* Style the tab content */
.chart_tabcontent {
  display: none;
  padding: 6px 12px; 
  border-top: none;
}
.chrt_dsc.clmn_cht {
    padding: 12px 40px;
}
.tb_blok
{
    display: block;
}
 
/* *************************************Areas Page css starts*********************************************************************************** */
 
.area_heading h3 {
    font-size: 28px;
    font-family: 'Poppins-SemiBold';
    color: #F96559;
    margin: 25px 17px;
}
.area_formwrapr .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #c3cacc59;
    border-radius: 6px;
    opacity: 1;
    min-height: 44px;
    color: #29455B;
    font-size: 16px;
    font-family: 'Poppins-Medium';
}
.area_formwrapr .form-control:focus {
    color: #29455B;
    border-color: #C3CACC;
    outline: 0;
    box-shadow: none;
}

.area_formwrapr .form-control::placeholder
{
    color: #ACBEC4;
}
.plus_add {
    margin: 13px 0;
}
.plus_add i {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #C3CACC;
    border-radius: 4px;
    opacity: 1;
    padding: 10px 20px;
    font-size: 16px;
    color: #697C8C;
    font-weight: 300;
    transition: 0.4s ease;
}
.plus_add i:hover{
    background: #697C8C;
    color: #fff;
}
.a_frm_buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 100px;
}
.cncl_btn {
    margin-right: 35px;
}
.cncl_btn a, .cncl_btn button {
    font-size: 16px;
    background: #F8F5F5;
    border: 1px solid #F96559;
    opacity: 1;
    padding: 6px 28px;
    border-radius: 24px;
    color: #F96559;
    font-family: 'Poppins-Medium';
    text-decoration: none;
    transition: 0.4s ease;
}
.cncl_btn a:hover, .cncl_btn button:hover {
     background: #F96559;
     color: #fff;
}
.save_btn a, .save_btn button  {
    font-size: 16px;
    background: linear-gradient(90deg, #fe904c, #fb731f);
    border: 1px solid #fb731f;
    padding: 7px 47px;
    border-radius: 24px;
    color: #FFFFFF;
    font-family: 'Poppins-Medium';
    box-shadow: 3px 5px 7px #57575730;
    opacity: 1;
    text-decoration: none;
    transition: 0.4s ease;
}
.save_btn a:hover, .save_btn button:hover{ 
    background: #fff;   
    color: #fb731f; 
} 

/* *************************************Ares Page css ends************************************************************** */

/* **************************************Table Qr page css********************************************************************************** */

.tbl_scnd_hedng h3 {
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
    color: #697C8C;
    margin: 25px 0;
}
.a_frm_buttons.tb_frm_btns {
    margin-top: 100px;
    margin-bottom: 100px;
} 

/* **************************************Table Qr page css Ends************************************************************** */

/* *****************************************Product Category Page ********************************************************** */
.tb_formm input[type="file"] { 
    z-index: -1;
    position: absolute;
    opacity: 0;
  }
  
  .tb_formm input:focus + label {
    outline: 2px solid;
  }
  
  .pr_upload label {
    padding: 14px 29px 14px 0px;
    color: #FEFEFE;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    margin-bottom: 2px;
    background: #F96559 0% 0% no-repeat padding-box;
    border: 0.5px solid #C3CACC;
    border-radius: 6px;
    opacity: 1;
    width: 100%;
    text-align: center;
    position: relative;
    cursor: pointer;
}
.fl_ipload_inpt i {
    font-size: 21px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.fl_name {
    margin-left: 8px;
    font-size: 12px;
    font-family: 'Poppins-Medium';
    color: #F96559;
}
.fl_name i {
    font-size: 16px;
    margin-left: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 5px 8px;
}
.dlt_icons i
{
    color: #F96559;
}
.prd_hdng h3 {
    font-size: 20px;
    font-family: 'Poppins-SemiBold';
    color: #29455B; 
} 
.prd_pra p {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #A7A7A7;
}
.pod_flxname {
    display: flex;
    align-items: center;
    margin: 12px 0;
    width: 100%;
    
}
.pd_h h3 {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #29455b91;
}
.pd_h2 h3 {
    font-size: 18px;
    font-family: 'Poppins-Medium';
    color: #3B3B3B;
    background: #F0F6FA 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    padding: 10px 20px;
    margin: 0 48px;
}
.pd_h3 ion-icon.md.hydrated {
    font-size: 18px;
    background: #F0F6FA 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    padding: 9px 9px;
    color: #FF3323;
    margin-right: 10px;
}
.produc_categ {
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
    background: #FEFEFE;
    padding: 23px 26px;
}
 
.pd_h4 ion-icon.md.hydrated {
    font-size: 21px;
    border-radius: 6px;
    padding: 9px 9px;
    margin-right: 10px;
    opacity: 1;
    color: #697C8C;
}
.pd_h {
    flex: 0 0 4%;
}
.pd_h2 {
    flex: 0 0 67%;
}
.pd_h3 {
    flex: 0 0 10%;
}
/* *****************************************Product Category page css ends***************************************************/

/* *****************************************Product page css ends***************************************************/
.ui-datepicker {
	width: 25em !important;
	padding: 0 !important;
 }
 .ui-widget.ui-widget-content {
	border: 0 !important;
	border-radius: 10px !important;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 13px #0a0a0a47 !important;
	opacity: 1;
 }
 .ui-datepicker th {
	color: #697C8C !important;
	font-size: 12px !important;
	font-family:'Poppins-Medium' !important;
 }
 .ui-state-default,
 .ui-widget-content .ui-state-default {
	color: #8796A3 !important;
	font-family: 'Poppins-Medium' !important;
	font-size: 12px !important;
	background: transparent !important;
 }
 .ui-state-active,
 .ui-widget-content .ui-state-active {
	border: none !important;
	background: #F96559 !important;
    color: #fff !important;
 }
 .ui-state-highlight,
 .ui-widget-content .ui-state-highlight,
 .ui-widget-header .ui-state-highlight {
	border: 1px solid #F96559 !important;
 }
  
 .ui-datepicker td span,
 .ui-datepicker td a {
	text-align: center !important;
 }
 .ui-datepicker td {
	border: 0;
	padding: 1px 15px !important;
 }
 .ui-state-default,
 .ui-widget-content .ui-state-default {
	border: 0 !important;
 }
 .ui-datepicker .ui-datepicker-prev span {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: -8px;
	background: url(../images/dat_lft.png);
	background-repeat: no-repeat;
	background-size: 49% 70%;
	position: relative;
    filter: brightness(0) invert(1);
 }
 .ui-datepicker .ui-datepicker-prev span::before {
	content: "\f068";
	font: normal normal normal 14px/1 FontAwesome;
	font-weight: 400;
	float: right;
	font-size: 20px;
	color: #000000;
	position: absolute;
	top: 0;
 }
 .ui-datepicker .ui-datepicker-prev {
    left: 86% !important;
}
 .ui-datepicker .ui-datepicker-next span {
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -8px;
	top: 50%;
	margin-top: -8px;
	background: url(../images/dat_rit.png);
	background-repeat: no-repeat;
	background-size: 49% 70%;
    filter: brightness(0) invert(1);
 }
 .ui-datepicker .ui-datepicker-prev,
 .ui-datepicker .ui-datepicker-next {
	top: 15px !important;
	width: 1em !important;
	height: 1em !important;
	cursor: pointer !important;
 }
 .ui-priority-secondary,
 .ui-widget-content .ui-priority-secondary,
 .ui-widget-header .ui-priority-secondary {
	color: #BBC3CA !important
 }
 
 .ui-datepicker .ui-datepicker-header {
	position: relative;
	border: 0;
	padding: 4px;
	border-radius: 7px;
	background: #F96559 0% 0% no-repeat padding-box;
	border-radius: 8px 8px 0px 0px;
	opacity: 1;
	color: #fff;
 }
 .ui-datepicker .ui-datepicker-title {
	text-align: left !important;
	font-size: 14px !important;
	font-family: 'Poppins-Medium' !important;
	padding: 5px 0 !important;
 }
 
 .radio_btnn {
	padding: 7px 21px;
 }
 .radio_btnn label {
	position: relative;
	font-size: 14px;
	display: inline-flex;
	align-items: flex-start;
	cursor: pointer;
 }
 .radio_btnn input:focus + label
 {
    outline: none;
 }
 
 .radio_btnn input[type="radio"]:checked,
 .radio_btnn input[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
 }
 .radio_btnn input[type="radio"]:checked + label, .radio_btnn input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 12px;
    display: inline-block;
    color: #29455B;
    font-size: 14px;
    font-family: 'Poppins-Medium';
}
 .radio_btnn input[type="radio"]:checked + label:before,
 .radio_btnn input[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 14px;
	height: 14px;
	border: 1px solid #BCBCBC;
	border-radius: 100%;
	background: #fff;
 }
 .cncl_lbl
 {
    color: #BCBCBC !important;
 }
 .radio_btnn input[type="radio"]:checked + label:after,
 .radio_btnn input[type="radio"]:not(:checked) + label:after {
	content: "";
	width: 10px;
	height: 10px;
	background: #f96559;
    border: 1px solid #f96559;
	position: absolute;
	left: 2px;
	top: 2px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
 }
 .radio_btnn input[type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
 }
 .radio_btnn input[type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
 }
 .radio_btnn input[type="radio"]:checked + label:before {
	 border: 1px solid #FF766B !important;
 }
 .radio_btnn p {
    margin-bottom: 12px;
}
/* *****************************************Product page css ends***************************************************/
/* ************************************************************************************************************************ */
/* ************************************************************************************************************************ */
/* ********************************************Order Managment Css**************************************************************************** */

 .order_mng_btns
 {
    display: flex;
    flex-direction: column;
 }
 .custom_ordr_btns {
    margin: 15px 0;
    padding: 11px 23px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #C3CACC;
    border-radius: 6px !important;
    opacity: 1;
    color: #697C8C;
    font-size: 14px;
    font-family: 'Poppins-SemiBold';
    text-align: left;
}

.btn.custom_ordr_btns.active
{
    border: 2px solid #FF766B;
}
 .btn.custom_ordr_btns.focus
 {
    box-shadow: none;
 }
 .prefrenc_com_form label {
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
    color: #697C8C;
    margin-bottom: 14px;
}
.prefrenc_com_form .form-control {
    padding: 8px 20px;
    height: 48px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F96559;
    border-radius: 6px;
    opacity: 1;
    font-family: 'Poppins-SemiBold';
    color: #697C8C;
    font-size: 14px;
}
.prefrenc_com_form .form-control:focus
{
    box-shadow: none;
}
.prefr_coment_inp .form-group {
    margin-bottom: 0;   
    margin-right: 7px;
    width: 100%;
}
.prefr_coment_inp {
    display: flex;
    align-items: center;
}
.prfr_hdng h3 {
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
    color: #697C8C;
    margin-left: 17px;
    margin-bottom: 13px;
}
.por_com_colse ion-icon {
    font-size: 30px;
    color: red;
    margin-bottom: 0;
    margin-top: 10px;
}
.prefr_coment_inp .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #C3CACC;
    border-radius: 6px;
    opacity: 1;
    color: #697C8C;
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
}
.prefr_coment_inp {
    display: flex;
    align-items: center;
    margin: 15px 0;
}


/* *********************************************Ordre Managment css ends*************************************************************************** */

/* *********************************************Analytics css starts*************************************************************************** */

.anal_selec .form-control {
    background: transparent;
    border: 0.5px solid #F96559;
    border-radius: 29px;
    opacity: 1;
    width: 135px;
    height: 46px !important;
    font-size: 16px;
    color: #29455B;
    font-family: 'Poppins-Medium';
    padding-left: 24px;
    cursor: pointer;
}
.anal_selec .form-control:focus
{
    box-shadow: none;
    outline: none;
}

.anal_selec select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
  }

  /* for IE10 */
  .anal_selec select::-ms-expand {
    display: none;
  }
  .anal_selec
  {
    position: relative;
  }
  .crt_icon {
    position: absolute;
    top: 60%;
    right: 14px;
    transform: translateY(-50%);
}
.crt_icon ion-icon {
    color: #f96559;
    opacity: 1;
    font-size: 18px;
    font-weight: 100;
}

.bsel_img {
    width: 100%;
    height: 135px;
    margin: 16px 0;
    position: relative;
}

.bsel_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}
.b_cart_hdnng h3 {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #29455B;
    margin-bottom: 10px;
}
.b_cart_last {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.b_left_h1 h3 {
    font-size: 18px;
    font-family: Poppins-ExtraBold;
    color: #29455B;
}
.b_lefth2_wraper {
    display: flex;
    align-items: baseline;
}
.wrap_seller1 {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.wrap_seller1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: unset;
}

.b_cart_hdnng h3 {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #29455B;
    margin-bottom: 16px;
}
.b_selr_2 {
    padding: 14px 30px;
}
.dsh_main_grph.table_menus
{
    padding: 14px 0;
}
.dsh_main_grph.table_menus .chrt_dsc {
     
    padding: 0 30px;
}
.b_seller_menu:not(:last-child) {
    border-bottom: 2px solid #E1E1E1;
    padding-bottom: 21px;
}
.wrap_seller2 h3 {
    font-size: 16px;
    color: #A7A7A7;
    font-family: 'Poppins-Regular';
}
.new_charts_analytics {
    padding-bottom: 134px;
}
.b_seller_menu a
{
    text-decoration: none;
}

/* Shine image animation effect */

/* .test-shine {  
    background-repeat: no-repeat; 
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 24px;
} */
  
  .test-shine:after {
    content: "";
    position: absolute;
      top: -50%;
    left: -60%;
    width: 20%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
  
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
  
  /* Hover state - trigger effect */
  .b_selr_2:hover .test-shine:after {
    opacity: 1;
    left: 130%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  
  /* Active state */
  .test-shine:active:after {
    opacity: 0;
  }



/* Shine image animation effect ends*/

/* *********************************************Analytics css ends*************************************************************************** */


/* *********************************************Sign up and Sign in  css starts*************************************************************************** */
.main_sgn_Wrapper {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    height: 100%;
}

.signup_wrapper {
    padding: 115px 209px 54px 209px;
    position: relative;
    overflow: hidden;
}
.singup_hdng h3 {
    margin: 15px 0 47px 0;
    text-align: center;
    font-family: Poppins-Semibold;
    color: #F96559;
    font-size: 32px;
}
.singnup_form .form-group {
    margin-bottom: 8px;
}
.singnup_form .form-control {
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #C3CACC;
    border-radius: 6px;
    opacity: 1;
    font-size: 14px;
    font-family: Poppins-Light;
    color: #ACBEC4;
}
.singnup_form .form-control:focus {
    box-shadow: none;
    border: 0.5px solid #f9655947;
}
.singnup_form .form-control::placeholder
{
    font-size: 14px;
    font-family: Poppins-Light;
    color: #ACBEC4;
}
.submt_btn a, .submt_btn button {
    display: block;
    width: 100%;
    background: #F96559 0% 0% no-repeat padding-box;
    border: 1px solid #F96559;
    border-radius: 6px;
    opacity: 1;
    padding: 12px 10px;
    text-align: center;
    color: #F8F5F5;
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    margin: 60px 0 18px 0;
    text-decoration: none;
    transition: 0.4s ease;
    cursor: pointer;
}
.submt_btn a:hover, .submt_btn button:hover {
     background: #fff;
     color: #F96559;
}
.already_acc p {
    color: #2C475D;
    font-size: 12px;
    font-family: Poppins-Medium;
    text-align: center;
}
.already_acc a {
    font-family: 'Poppins-Medium';
    color: #F96559;
    font-size: 12px;
    text-decoration: none;
}

.forgt_pass {
   text-align: right;
}


.forgt_pass a {
    color: #F96559;
    font-size: 12px;
    font-family: 'Poppins-Medium';
    text-decoration: none;
}
.sign_lft {
    width: 284px;
    height: 326px;
    position: absolute;
    left: 0;
    top: 0;
}
.sign_lft img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sign_lft.sgn_rit {
    left: unset;
    right: -122px;
    top: -67px;
    width: 441px;
    height: 411px;
    overflow: hidden;
}
.sign_lft.sgn_b_lft {
    top: unset;
    bottom: -1px;
    left: -2px;
}
.sign_lft.sgn_b_rit {
    left: unset;
    right: 0;
    top: unset;
    bottom: 0;
    width: 284px;
    height: 327px;
}
/* *********************************************Sign up and Sign in  css ends*************************************************************************** */


/* *********************************************QR scan Modal css ends*************************************************************************** */


.qr_modal .modal-dialog {
    max-width: 50% !important;
}

.qr_modal .modal-content
{
    background: transparent;
    border: none;
}

.qr_modal .fade.show {
    opacity: 1;
    background: #70707078;
    backdrop-filter: blur(5px);
}
.qr_hdng h3 {
    font-size: 61px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins-SemiBold';
    margin: 20px 0 82px 0;
}

.qr_wrapper {
    background: #fff;
    position: relative;
}
.qr_icon {
    text-align: center;
}

.qr_icon i {
    font-size: 432px;
    padding: 60px 0px;
    color: rgb(41, 69, 91);
}
.qr_lft {
    width: 120px;
    height: 120px;
    position: absolute;
    left: -23px;
    top: -23px;
}
.qr_lft img
{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.qr_lft.qr_rit {
    left: unset;
    right: -23px;
}
.qr_lft.qr_btm_l {
    top: unset;
    bottom: -23px;
}
.qr_lft.qr_btm_r {
    left: unset;
    top: unset;
    bottom: -23px;
    right: -23px;
}

/* *********************************************QR scan Modal css ends*************************************************************************** */

/* *********************************************Food Details Page Starts*************************************************************************** */
.food_price_wrapper {
    padding-left: 6px;
}
.fd_wraper_mg {
    width: 100%;
    position: relative;
    padding-top: 25%; 
    margin-bottom: 30px;
    margin-top: 10px;
}
.fd_wraper_mg img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}
.fd_pheadng h3 {
    font-size: 24px;
    font-family: 'Poppins-Bold';
    color: #29455B;
}
.fd_pric {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.p_hdn_2 h3 {
    font-size: 18px;
    font-family: 'Poppins-Bold';
    margin-left: 125px;
    color: #F96559;
}
.frd_hdg h3 {
    font-size: 16px;
    color: #29455B;
    font-family: 'Poppins-Medium';
}
.food_option_select {
    margin: 13px 75px 13px 0;
} 

.food_option_btn label {
    margin: 17px 40px 17px 22px;
    padding: 10px 47px;
    border-radius: 24px !important;
    background: #EBEAEF;
    border: 2px solid #EBEAEF;
}
.btn.fd_slct_optss.active {
    background: #FF766B;
    color: #F8F5F5;
}
 
.fd_slct_optss {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #697C8C;
}
 
.btn.fd_slct_optss.focus {
    border: none;
    box-shadow: none !important;
    outline: none !important;
}
.food_option_select textarea {
    width: 100%;
    background: #EBEAEF;
    box-shadow: 1px 2px 4px #5e5e5e14;
    border-radius: 20px;
    opacity: 1;
    margin: 31px 0;
    padding: 16px;
    font-size: 12px;
    color: #2e4765bf;
    border: none;
    resize: none;
}
.food_option_select textarea:focus-visible {
    border: none;
    outline: 1px solid #00800047;
}
.addon_hdng h3 {
    font-size: 16px;
    font-family: 'Poppins-Bold';
    color: #29455B;
    margin: 5px 0 10px 21px;
}
.addons_p p {
    font-size: 12px;
    font-family: Poppins-Light;
    color: #A7A7A7;
    margin-left: 22px;
}
.addon_options {
    flex-direction: column;
    width: 100%; 
    margin: 18px 0;
}
.addon_flex_wrap {
    display: flex;
    align-items: center;
    padding: 0px 0px;
}
.adon_1 h5 {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #29455b6b;
}

.adon_2 h3 {
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color: #3B3B3B;
}
.adon_2 h4 {
    font-size: 16px;
    font-family: 'Poppins-Bold';
    color: #29455B;
    text-align: left;
    margin-top: 5px;
}
.adon_2 {
    margin-left: 37px;
    margin-top: 24px;
}
.addon_tbl_img {
    width: 68px;
    height: 79px;
    padding: 3px 4px;
}
.addon_tbl_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}
.addon_3
{
    margin-left: auto;
}

.btn.fd_slct_optss.add_slct_optss {
    border: 0.20000000298023224px solid #00000000;
}
.btn.fd_slct_optss.add_slct_optss.active {
    background: #ff766b;
    color: #F8F5F5;
    border: 0.20000000298023224px solid #ff766b;
    opacity: 1;
    margin-bottom: 10px;
}
.btn.fd_slct_optss.add_slct_optss.active > .addon_flex_wrap * {
    color: #fff;
}
.fd_detail_addons {
    background: #FEFEFE;
    padding: 13px 0;
    box-shadow: 9px 17px 26px #a1add119;
    border-radius: 20px;
    opacity: 1;
}
.add_on_submit {
    text-align: center;
    margin: 46px 0 30px 0;
}
.add_on_submit a, .add_on_submit button  {
    background: #FF766B;
    padding: 9px 74px; 
    color: #FFf;
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    text-decoration: none;
    transition: 0.4s ease;
    border: 1px solid #FF7668;
    box-shadow: 0px 3px 8px #3AB54B24;
    border-radius: 30px;
    opacity: 1;
}
.add_on_submit a:hover, .add_on_submit button:hover {
    background: #fff;
    color: #FF7668;
    cursor: pointer;
}

.fod_detai_done a, .fod_detai_done button 
{
    background: #FF766B;
    padding: 9px 41px; 
    color: #FFf;
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    text-decoration: none;
    border: 1px solid #FF7668;
    box-shadow: 0px 3px 8px #3AB54B24;
    border-radius: 30px;
    opacity: 1;
    transition: 0.4s ease;
}
.fod_detai_done a:hover, .fod_detai_done button:hover {
    background: #fff;
    color: #FF7668;
}
 
.pric_hdng h3 {
    text-align: center;
    padding: 34px 0;
    color: #707070;
    font-family: 'Poppins-SemiBold';
    font-size: 45px;
}
.pric_modal .fade.show {
    opacity: 1;
    background: #70707078;
    backdrop-filter: blur(5px);
}
 
.pric_modal .modal-dialog {
    max-width: 70%;  
}
.pric_modal .modal-content
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 34px; 
    border: none;
}
.pricng_cardswrap {
    max-width: 64%;
    margin: 49px auto;
}
.p_crds_pop {
    box-shadow: 3px 12px 57px #74747457;
    border-radius: 13px;
    opacity: 1;
    padding: 23px 39px;
    position: relative;
    background: #fff;
    overflow: hidden;
}
.pop_f_one h3 {
    font-size: 22px;
    text-align: center;
    font-family: 'Poppins-SemiBold';
    color: #fff;
    position: relative;
    z-index: 1;
    margin-top: 20px;
}
.pop_f_two {
    position: relative;
    z-index: 1;
    text-align: center;
}
.pop_f_two h3 {
    font-size: 28px;
    font-family: mont-bold;
    text-align: center;
    color: #fff;
    margin: 10px 0 0;
    display: inline-block;
}
.dlr_pop i {
    font-size: 18px;
    color: #fff;
    text-align: center;
}
.dlr_pop {
    position: absolute;
    left: 27%;
    z-index: 1;
}
.radio_btns_pricng {
    margin: 57px 0 17px 0;
}
.pric_wrappr_pop {
    padding: 44px 0 67px 0;
}

.p_crds_pop::after {
    position: absolute;
    background: transparent linear-gradient(16deg, #FF5A09 0%, #FFCBB1 100%, #000000 100%) 0% 0% no-repeat padding-box;
    width: 128%;
    height: 245px;
    content: "";
    left: -65px;
    border-radius: 50%;
    top: -93px;
    z-index: 0;
}
button.btn.monthly {
    color: #707070;
    font-family: 'Poppins-SemiBold';
    font-size: 20px;
    background: #DBE4E9 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 8px 20px;
}

.radio_btns_pricng input[type='radio'][name='planType_1'] { display:none; }
.radio_btns_pricng input[type='radio'][name='planType_2'] { display:none; }
.radio_btns_pricng label {
    display: inline-block;
    padding: 14px;
    border: none; 
    background: #DBE4E9 0% 0% no-repeat padding-box; 
    opacity: 1;
    width: 7em;
    text-align: center;
    color: #707070;
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    cursor: pointer;
}

/* Clicking a label will select its corresponding hidden radio button
   We can select that radio buttons sibling label and style it. */
.radio_btns_pricng input[type='radio'][name='planType_1']:checked + label 
{
    background-color: #FF691F;
    color: #fff; 
}
.radio_btns_pricng input[type='radio'][name='planType_2']:checked + label 
{
    background-color: #FF691F;
    color: #fff; 
}


.orang_sbs_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 163px;
    z-index: 0;
}
.orang_sbs_img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.pop_f_two p {
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Poppins-Regular';
}
.pric_mnthly {
    display: flex;
    justify-content: center;
}
label.anll { 
    border-radius: 0px 10px 10px 0px;  
}
label.mnth {
    border-radius: 10px 0px 0px 10px;
}
.prcng_desc p {
    font-size: 14px;
    text-align: center;
    color: #707070;
    font-family: 'Poppins-SemiBold';
}
.prcng_buy_btn {
    margin: 32px 0;
    text-align: center;
    transition: 0.4s ease;
}
.prcng_buy_btn a, .prcng_buy_btn button  {
    background: transparent linear-gradient(338deg, #FF5A09 0%, #FF874B 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    font-family: 'Poppins-Bold';
    padding: 9px 45px;
    box-shadow: 3px 12px 57px #74747457;
    border-radius: 24px;
    opacity: 1;
    border: 1px solid #FF5A09;
    transition: 0.4s ease;
    text-decoration: none;
}
.prcng_buy_btn a:hover, .prcng_buy_btn button:hover
{
    background: #fff;
    color: #FF5A09;
}
/* *********************************************Food Details Page Ends************************************************************************* */


/* Media Queries Starts Here */
/* Media Queries Starts Here */
/* Media Queries Starts Here */

@media screen and (max-width:1770px) {
    .food_option_btn label {
        margin: 17px 19px 17px 22px;
        padding: 10px 43px; 
    }
    .fd_slct_optss {
        font-size: 14px; 
    }
    .pric_modal .modal-dialog {
        max-width: 80%;
    }
}

@media screen and (max-width:1650px){
    .cartBar{
        width: 350px;
    }
    .contentArea{
        max-width: calc(100% - 670px);
    }
    .contentArea.closed{
        max-width: calc(100% - 570px);
    }
    .signup_wrapper {
        padding: 84px 0 54px 0;
    }
    .pricng_cardswrap {
        max-width: 86%;
        margin: 49px auto;
    }
    .pric_modal .modal-dialog {
        max-width: 90%;
    }
}
@media screen and (max-width:1599px){
    .cartBar{
        width: 300px;
    }
    .contentArea{
        max-width: calc(100% - 620px);
    }
    .contentArea.closed{
        max-width: calc(100% - 520px);
    }
}
@media screen and (max-width:1400px){
    .serch_bar>input{
        max-width: calc(100% - 60px);
    }
    .mc_amount{
        margin-left: 30px
    }
    .hdr_mid {
        width: 100%;
        display: flex;
        margin: 0 0px 0 10px;
        align-items: center;
        flex: 0 0 51%;
        transition: 0.4s ease;
    }
    .hdr_rit {  
        flex: 0 0 24%;
    }
    .hdr_mid.closed {
        flex: 0 0 61% !important;
    }
    .adminr_left {
        flex: 0 0 14%;
    }
    .adminr_right {
        flex: 0 0 86%;
    }
    .sid_descrp p {
        font-size: 12px;
    }
    .sid_adrmnr {
        margin-right: 8px;
        width: 22px;
        height: 22px;
    }
    .side_mnuwrap {
        padding-left: 12px;
    }
    .srch_icon { 
        left: 9%; 
    }
    .dmnu_2 h4 {
        font-size: 12px;  
    }
    .dnmp4 p {
        font-size: 10px; 
        margin-right: 11px;
    }
    .ad_newmenu {
        width: 45px;
        height: 42px;
    }
    .bsel_img { 
        height: 86px; 
    }
    .b_left_h1 h3 {
        font-size: 16px; 
    }
    .wrap_seller1 {
        width: 20px;
        height: 20px; 
    }
    .wrap_seller2 h3 {
        font-size: 14px; 
    }
    svg.radial-progress { 
        max-width: 100px; 
    }
    .admn_txt h3 {
        font-size: 22px; 
    }
    .qr_hdng h3 {
        font-size: 47px; 
        margin: 20px 0 47px 0;
    }
    .fd_pheadng h3 {
        font-size: 18px; 
    }
    .p_hdn_2 h3 {
        font-size: 14px; 
        margin-left: 100px; 
    }
    .frd_hdg h3 {
        font-size: 14px; 
    }
    .food_option_btn label {
        margin: 17px 22px 17px 14px;
        padding: 8px 23px; 
    }
    .addon_tbl_img {
        width: 63px;
        height: 71px;
        padding: 3px 4px;
    }
    .left_sidebarWrap {
        width: 246px;
        background: #ffff;
        position: fixed;
        top: 115px;
        left: 0;
        padding: 8px 24px;
        height: calc(100vh - 120px);
        overflow: auto;
        transition: ease-in-out 0.2s;
    }
    .lsb_listItem h3 {
        font-size: 16px; 
    }
    .lsb_innerWrap .lsb_list { 
        max-width: calc(100% - 25px); 
    }
    .contentArea {
        max-width: calc(100% - 566px);
        margin-left: 246px;
    } 
}
@media screen and (max-width:1200px){
    button.cart_cino{
        display: block;
    }
    .cartBar{
        right: -100%;
       /*  box-shadow: rgb(249 118 76 / 10%) -5px -5px, rgb(249 103 89 / 13%) -10px -10px, rgb(249 78 107 / 12%) -15px -15px, rgb(249 83 103 / 9%) -20px -20px, rgb(249 80 106 / 8%) -25px -25px; */
       border-left: 3px solid #f1592a !important;
        border-right: 0;
        padding: 8px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        top: 95px;
        box-shadow: rgb(0 0 0 / 29%) 8px 15px 16px 8px;
    }
    .cartBar.active{
        right: -16px;
       /*  box-shadow: rgb(249 118 76 / 10%) -5px -5px, rgb(249 103 89 / 13%) -10px -10px, rgb(249 78 107 / 12%) -15px -15px, rgb(249 83 103 / 9%) -20px -20px, rgb(249 80 106 / 8%) -25px -25px; */
    }
    .contentArea{
        max-width: calc(100% - 200px);
        margin-left: 200px;
    }
    .contentArea.closed{
        max-width: calc(100% - 200px);        
    }
    .left_sidebarWrap{
        z-index: 9;
        box-shadow: rgb(0 0 0 / 29%) 8px 15px 16px 8px;
        border-top-right-radius: 16px;
        border-right: 3px solid #f1592a;
        /* width: 200px;
        box-shadow: none;
        border-top-right-radius: 0;
        border-right: none; */
    }
    .left_sidebarWrap.closed{
        width: 200px;
        box-shadow: none;
        border-top-right-radius: 0;
        border-right: none;
    }
    .left_sidebarWrap.closed .lsb_listItem h3 {
        font-size: 18px;
    }
    .left_sidebarWrap.closed .nav-text{
        display: none;
    }
    .left_sidebarWrap.closed .lsb_list {
        max-width: calc(100% - 0px);
    }
    .left_sidebarWrap.closed .lsb_list .lsb_listItem {
        width: auto;
    }
    .left_sidebarWrap.closed .lsb_list .lsb_listItem .side_loigow {
        margin-right: 0;
    }
    .nav_logo{
        flex: 0 0 200px;
    }
    .nav_innerWrap.closed .nav_logo {
        flex: 0 0 200px;
    } 
    .sidebarLayer.closed{
        display: none;
    }
    .primry_btn.crdBtn{
        padding: 8px 16px;
    }
    .infoList {
        font-size: 10px;
    }
    .productItem {
        min-width: 240px;
    }
    /* .mc_amount p:first-child{
        display: none;
    } */
    .toggleMenu span{
        margin-right: auto;
        margin-left: unset;
    }
    .toggleMenu.active span:nth-child(3) {
        -webkit-transform: translateY(-2px) rotate( 220deg);
        transform: translateY(-2px) rotate( 220deg);
    }
    .toggleMenu.active span:nth-child(1) {
        -webkit-transform: translateY(2px) rotate( 140deg);
        transform: translateY(2px) rotate( 140deg);
    }
    .hdr_rit {
        flex: 0 0 29%;
    }
    .hdr_mid { 
        flex: 0 0 42%; 
    }
    .adminr_left {
        flex: 0 0 18%;
        position: absolute;
        display: none;
    }
    .adminr_left.side_active
    {
        display: block !important;
    }
    .adminr_right {
        flex: 0 0 100%;
    }
    .hdr_mid.closed {
        flex: 0 0 55% !important;
    }
    .sign_lft {
        width: 125px;
        height: 144px;
    }
    .sign_lft.sgn_rit {
        right: -57px;
        top: -58px;
        width: 186px;
        height: 232px;
    }
    .sign_lft.sgn_b_lft {
        top: unset;
        bottom: -1px;
        left: -2px;
        width: 125px;
        height: 146px;
    }
    .sign_lft.sgn_b_rit {
        left: unset;
        right: 0;
        top: unset;
        bottom: 0;
        width: 123px;
        height: 141px;
    }
    .adminr_left.closed {
        display:block !important;
    }
    .sid_descrp.closed{
        display: block !important;
    }
    .rest_logo.closed{
        width: 205px !important;
        height: 51px !important;
        margin-left: 39px !important;
        flex: 0 0 10% !important;
        transition: 0.4s ease !important;       
    }
    .header_secc{
        flex-wrap: nowrap;
    }
     
}
@media screen and (max-width:991px){
    .left_sidebarWrap.closed{
        left: -100%;
    }
    .left_sidebarWrap{
        top: 95px;
        height: calc(100vh - 95px);
    }
    .contentArea, .contentArea.closed{
        max-width: 100%;
        margin-left: 0;
    }
    .productArea{
        height: calc(100vh - 230px);
    }
    .nav_innerWrap{
        padding: 10px;
    }
    .usr_titl h3{
        font-size: 22px;
    }
    .nav_dropdown{
        width: 40px;
        height: 40px;  
    }
    .serch_bar>input {
        max-width: calc(100% - 20px);
        padding: 10px 10px 10px 30px;
    }
    .categoryBar{
        border-radius: 16px;
        margin-left: 20px;
    }
    .productItem{
        flex: 1 0 calc((100% / 3) - 20px);
    }
    .nav_innerWrap.closed .nav_logo,.nav_innerWrap .nav_logo {
        flex: 0 0 100px;
    }
    .sub_totlButn {
        margin: 16px 0 0 0 ;
    }
    .sub_totlButn button {
        border-radius: 8px;
        font-size: 14px;
    }
    #a li span, .btn-select li span { 
        font-size: 10px !important;
    }
    .hdr_mid.closed {
        flex: 0 0 52% !important;
    }
    .dsh_main_grph
    {
        margin-bottom: 20px;
    }
    .hdr_mid {
        flex: 0 0 47%;
    }
    .srch_icon {
        left: 6%;
    }
    .a_frm_buttons { 
        margin-top: 100px;
        margin-bottom: 40px;
    }
    .bsel_img {
        height: 234px;
    } 
    .signup_wrapper {
        padding: 109px 0 54px 0;
    }
    .qr_modal .modal-dialog {
        max-width: 85% !important;
        margin: auto;
    }
    .p_crds_pop::after {
        position: absolute;
        background: transparent linear-gradient(16deg, #FF5A09 0%, #FFCBB1 100%, #000000 100%) 0% 0% no-repeat padding-box;
        width: 146%;
        height: 262px;
        content: "";
        left: -65px;
        border-radius: 50%;
        top: -93px;
        z-index: 0;
    }
}
@media screen and (max-width:767px){
    .serch_bar{
        display: none;
    }
    .cartBar {
        width: 270px;
    }
    .categoryList button{
        padding: 5px 15px 5px 5px;
        margin: 10px 15px 15px 0px;
        min-width: 110px;
        font-size: 12px;
    }
    .tb_img{
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    .tb_sp{
        width: 30px;
        height: 15px;
    }
    .heading>h3 {
        font-size: 16px;
    }
    .nav_dropdown {
        width: 35px;
        height: 35px;
    }
    .lsb_innerWrap .lsb_list .lsb_listItem a{
        font-size: 14px;
    }
    .img_sideicon{
        padding: 8px;
        border-radius: 5px;
        width: 40px;
        height: 40px;
    }
    .lsb_innerWrap .lsb_list .lsb_listItem a{
        border-radius: 5px;
    }
    .lsb_listItem h3{
        font-size: 18px;
    }
    .lsb_innerWrap .lsb_list{
        max-width: calc(100% - 20px);
    }
    .left_sidebarWrap {
        padding: 8px 10px;
        width: 200px;
        
    }
    .cp_title>h3, li.cartItem{
        font-size: 12px;
    }
    .cp_img{
        height: 60px;
        width: 55px;
        flex: 0 0 55px;
    }
    .hdr_rit {
        flex: 0 0 35%;
        margin-left: auto;
    }
    .hdr_mid {
        flex: 0 0 34%;
        justify-content: space-between;
    }
    .rest_logo.closed {
        width: 38px !important;
        height: 38px !important; 
        flex: 0 0 14% !important; 
    }
    .hdr_mid.closed {
        flex: 0 0 40% !important;
        justify-content: space-between;
    }
    .log_img {
        width: 50px;
        height: 50px; 
    }
    .rest_logo {
        width: 205px;
        height: 51px;
        margin-left: 21px;
        flex: 0 0 14%;
        transition: 0.4s ease;
    }
    .menu_togg.active span:nth-child(1) {
        -webkit-transform: translateY(2px) rotate( 45deg);
        transform: translate(18px, 3px) rotate( 45deg);
    }
    .chrt_dsc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .chrt_dsc.clmn_cht {
        padding: 12px 9px;
        margin: 15px 0;
    } 
    .chart_tab button.active {
        padding: 6px 10px;
        margin: 9px 5px; 
    }
    .right_dashboard {
        margin: 10px 12px; 
    }
    .dn_menu_left {
        margin: 20px 15px;
    }
    .pr_upload label {
        padding: 14px 60px 14px 6px; 
    }
    .prefr_coment_inp { 
        margin-right: 30px;
    } 
    
    .signup_wrapper { 
        margin-bottom: 113px;
    }
    .dlr_pop {
        position: absolute;
        left: 38%;
        top: 18%;
        z-index: 1;
    }
    .p_crds_pop { 
        margin-bottom: 29px;
    }
    .pop_f_two h3 {
        font-size: 24px; 
    }
}
@media screen and (max-width:580px){
    .serch_bar{
        display: none;
    }
    .left_nav{
        width: auto;
        order: 1;
    }
    .nav_innerWrap{
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .logo_img{
        width: 70px;
        height: 50px;
    }
    .nav_logo{
        order: 2;
    }
    .right_nav{
        width: 100%;
        justify-content: space-between;
        order: 3;
        padding: 0 10px;
    }
    .right_nav .dropdown+.dropdown{
        padding-left: 8px;
    }
    button.cart_cino {
        margin-left: 8px;
    }
    .usr_titl h3{
        font-size: 18px;
    }
    .left_sidebarWrap, .cartBar{
        top: 125px;
    }
    /* .mc_amount{
        display: none;
    } */
    .d-flex.qr_notifc
    {
        display: none !important    ; 
    }
    .rest_logo {
        flex: 0 0 38%;
        margin-right: 12px;
        margin-bottom: 8px;
    }
    .hdr_mid {
        flex: 0 0 25%;
        margin-left: 17px;
    } 
    .hdr_mid.closed {
        flex: 0 0 10% !important;
        justify-content: space-between;
    }
    .hdr_rit {
        flex: 0 0 30%;
        margin-right: 9px;
        margin-left: 0px;
    }
    .btn-select { 
        padding: 5px 34px 7px 13px !important;
    } 
    .d-flex.mob_notific {
        display: flex !important; 
        margin-top: 8px !important;
        margin-left: 25px !important;
    }
    .log_img {
        width: 40px;
        height: 40px;
    }
    .d-flex.mob_notific {
        display: flex !important;
        margin-top: 6px !important;
        margin-left: 18px !important;
        flex: 0 0 11%;
        margin-right: 9px;
    }
    #a img, .btn-select img {
        width: 18px !important;
    }
    .log_img {
        width: 42px !important;
        height: 42px !important;
    }
    .log_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    } 
    .header_secc { 
        flex-direction: row-reverse;
    }
    .b {
        width: 59% !important;
        top: 130px !important;
        right: 11px !important;
    }
    .lang-select { 
        position: unset !important;
    }
    .chart_tab button { 
        padding: 6px 6px;
        margin: 11px 7px; 
        font-size: 12px; 
    }
    .admnr_sidbr {
        height: calc(100vh - 289px) !important; 
    }
    .area_formwrapr .form-control {
        font-size: 12px;
    }
    .area_heading h3 {
        font-size: 18px;
    }
    .a_frm_buttons.tb_frm_btns {
        margin-top: 70px;
        margin-bottom: 100px;
    }
    .produc_categ { 
        padding: 23px 4px;
    }
    .pd_h2 h3 {
        font-size: 12px;
        margin: 0 10px;
    }
    .pd_h2 {
        flex: 0 0 63%;
    }
    .pd_h3 ion-icon.md.hydrated {
        font-size: 14px; 
        margin-right: 7px;
    }
    .pd_h4 ion-icon.md.hydrated {
        font-size: 15px; 
        margin-right: 0; 
    }
    .prd_hdng h3 { 
        margin-bottom: 5px;
    }
    button.qr_cino { 
        width: 35px;
        height: 35px; 
    }
    button.notification { 
        width: 35px;
        height: 35px; 
    }
    .prefr_coment_inp .form-group {
        margin-bottom: 0;
        margin-right: 4px;
        width: 100%;
    }
    .bsel_img {
        height: 170px;
    }
    .anal_selec .form-control { 
        margin-top: 11px;
    }
    .ui-datepicker {
        width: 74% !important;
        padding: 0 !important;
    }
    .ui-datepicker td {
        border: 0;
        padding: 3px 7px !important;
    }
    .ui-datepicker th { 
        font-size: 10px !important; 
    }
    .afmn_r_wrapper
    {
        padding-bottom: 150px;
    }
    /* .right_dashboard { 
        height: calc(100vh - 270px); 
    } */
    .sign_lft {
        width: 99px;
        height: 113px;
    }
    .signup_wrapper {
        padding: 134px 0 56px 0;
    }
    .sign_lft.sgn_rit {
        right: -41px;
        top: -56px;
        width: 155px;
        height: 198px;
    }
    .sign_lft.sgn_b_lft { 
        width: 100px;
        height: 118px;
    }
    .sign_lft.sgn_b_rit { 
        width: 100px;
        height: 116px;
    }
    .qr_icon i {
        font-size: 160px !important; 
    }
    .qr_lft {
        width: 80px !important;
        height: 80px !important; 
    }
    .qr_lft.qr_btm_r {
         
        bottom: -16px;
        right: -16px;
    }
    .qr_lft.qr_btm_l {
        top: unset;
        bottom: -19px;
    }
    .qr_lft {
        width: 120px;
        height: 120px;
        position: absolute;
        left: -16px;
        top: -17px;
    }
    .qr_lft.qr_rit {
        left: unset;
        right: -16px;
    }
    .qr_hdng h3 {
        font-size: 24px; 
    }
    .fd_wraper_mg {
        width: unset;
        position: relative;
        padding-top: 39%;
        margin-bottom: 30px;
        margin-top: 18px;
        min-height: 148px;
        margin-right: 10px;
        border-radius: 24px !important;
    }
    .fd_wraper_mg img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: none;
        border-radius: 24px;
    }
    .fd_detail_addons { 
        margin-top: 17px;
    }
    .fd_slct_optss {
        font-size: 10px;
    }
    .food_option_btn label {
        margin: 17px 10px 17px 14px;
        padding: 6px 8px;
    }
    .categoryBar { 
        margin-left: 0px;
    }
    .food_option_select {
        margin: 13px 17px 13px 0;
    }
    .adon_2 h4 {
        font-size: 12px; 
    }
    .adon_2 h3 {
        font-size: 12px; 
    }
    .adon_1 h5 {
        font-size: 12px; 
    }
    .addon_tbl_img {
        width: 63px;
        height: 66px;
        padding: 3px 4px;
    }
    .dlr_pop {
        left: 32%;
        top: 17%;
    }
    .pric_modal .modal-dialog {
        max-width: 91%;
        margin: auto;
    }
    .pricng_cardswrap {
        max-width: 100%;
        margin: 49px auto;
    }
    .radio_btns_pricng label { 
        font-size: 12px; 
    }
    .pric_hdng h3 {  
        padding: 4px 0; 
        font-size: 24px;
    }    
}

@media (max-width: 510px){
    .qr_img{
        width: 100%;
    }
}

@media screen and (max-width:390px) { 
    .lang-select {
        margin-left: 4px !important; 
    }
    .bsel_img {
        height: 118px;
    }
    .dlr_pop {
        left: 30%;
        top: 17%;
    }
}
@media screen and (max-width:370px) { 
    .pop_f_two h3 {
        font-size: 18px;
    }
    .dlr_pop i {
        font-size: 13px;
        color: #fff;
        text-align: center;
    }
}
/* Media Queries Ends Here */
/* Media Queries Ends Here */
/* Media Queries Ends Here */

#loading
{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff 
    url("../images/editor-0.9s-47px.gif")
    no-repeat center;
    z-index: 9999999;
}


/* loading */

.cdev {
    position: relative;
    height: 100px;
    width: 100px;
    margin:0 auto;
  }
   
  .cdev div {
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
   
  .cdev div span {
    position: absolute; 
    font-size: 0;
    line-height: 75px;
    height: 75px;
    width: 75px;
    left: 12.5px;
    top: 12.5px;
    text-align: center;
    border-radius: 50%;
    background-color: white;
  }
   
  .cdev .background { background-color: #b3cef6; }
   
  .cdev .rotate {
    clip: rect(0 50px 100px 0);
    background-color: #4b86db;
  }
   
  .cdev .left {
    clip: rect(0 50px 100px 0);
    opacity: 1;
    background-color: #b3cef6;
  }
   
  .cdev .right {
    clip: rect(0 50px 100px 0);
    transform: rotate(180deg);
    opacity: 0;
    background-color: #4b86db;
  }

  .rdio_hdng h3 {
    font-size: 16px;
    font-weight: bold;
    color: #f96559;
    margin-bottom: 12px;
}